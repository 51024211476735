import { TFunction } from 'i18next';
import * as Yup from 'yup';

import {
  firstNameSchema,
  lastNameSchema,
} from '@components/Formik/validation/fieldDefinitions';

const firstLastNameSchema = (t: TFunction) => {
  const schema = {
    firstName: firstNameSchema(t),
    lastName: lastNameSchema(t),
  };

  return Yup.object(schema);
};

export default firstLastNameSchema;
