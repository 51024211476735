import React from 'react';

import NewContact from '@components/Pages/ContactUs';
import NotFound from '@components/Routing/NotFound';

import { FEATURE_CONTACT_US_PAGE, isEnabled } from '@utils/features';

const NewContactPage: React.FC = () => {
  if (isEnabled(FEATURE_CONTACT_US_PAGE)) {
    return <NewContact />;
  }
  return <NotFound />;
};

export default NewContactPage;
