import React from 'react';

import { ContactUsFormType } from '@domain/contact';

import { useDISCountries } from '@repositories/disCountry';

import { getCountryCode } from '@utils/getCountryCode';

import { useTranslation } from '@external/react-i18next';

const ContactUsLoggedInUser: React.FC<ContactUsFormType> = ({
  firstName,
  lastName,
  email,
  primaryPhone,
  country,
  club,
  district,
}) => {
  const { t } = useTranslation();
  const { data } = useDISCountries();

  const selectedCountry = data?.countries.find(
    country => country.name === primaryPhone?.country
  );
  const countryId = `+${getCountryCode(selectedCountry?.shortCode)}`;

  return (
    <>
      <>
        <div className="font-bold text-sm">
          {t('contact.us.form.firstname-label', 'First Name')}
        </div>
        <div className="text-sm">{firstName}</div>
      </>
      <>
        <div className="font-bold text-sm mt-5">
          {t('contact.us.form.lastname-label', 'Last Name')}
        </div>
        <div className="text-sm">{lastName}</div>
      </>
      <>
        <div className="font-bold text-sm mt-5">
          {t('contact.us.form.email-label', 'Email')}
        </div>
        <div className="text-sm">{email}</div>
      </>
      <>
        <div className="font-bold text-sm mt-5">
          {t('contact.us.form.phone-label', 'Phone')}
        </div>

        <div className="text-sm">
          {countryId} {primaryPhone.number}
        </div>
      </>
      <>
        <div className="font-bold text-sm mt-5">
          {t('contact.us.form.address.country-label', 'Country')}
        </div>
        <div className="text-sm">{country}</div>
      </>
      <>
        <div className="font-bold text-sm mt-5">
          {t('contact.us.form.filters-club-label', 'Club Name')}
        </div>
        <div className="text-sm">{club}</div>
      </>
      <>
        <div className="font-bold text-sm mt-5">
          {t('contact.us.form.filters-district-label', 'District')}
        </div>
        <div className="text-sm mb-5">{district}</div>
      </>
    </>
  );
};

export default ContactUsLoggedInUser;
