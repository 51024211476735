export enum SUBJECT_CATEGORIES {
  BRAND_CENTER = 'Brand Center',
  CONTRIBUTIONS_AND_RECOGNITION = 'Contributions and recognition',
  CONVENTION_OR_INTERNATIONAL_ASSEMBLY = 'Convention/International Assembly',
  DISCUSSION_GROUPS = 'Discussion Groups',
  MEMBERSHIP = 'Membership',
  MY_ACCOUNT_OR_PROFILE = 'My Account/Profile',
  MY_ROTARY_SIGN_IN_OR_REGISTRATION = 'My Rotary sign in/registration',
  PROJECTS = 'Projects',
  ROTARY_CLUB_GOALS = 'Rotary Club goals',
  ROTARY_GLOBAL_REWARDS = 'Rotary Global Rewards',
  UPDATE_CLUB_OR_DISTRICT_INFORMATION = 'Update Club/District information',
  YOUTH_PROGRAMS_OR_SERVICE = 'Youth programs/service',
  OTHER = 'Other',
}

export enum SUBJECT_RECIPIENTS {
  BRAND_CENTER_EMAIL = 'rotarysupportcenter@rotary.org',
  CONTRIBUTIONS_AND_RECOGNITION_EMAIL = 'rotarysupportcenter@rotary.org',
  CONVENTION_OR_INTERNATIONAL_ASSEMBLY_EMAIL = 'rireg@rotary.org',
  DISCUSSION_GROUPS_EMAIL = 'rotarysupportcenter@rotary.org',
  MEMBERSHIP_EMAIL = 'rotarysupportcenter@rotary.org',
  MY_ACCOUNT_OR_PROFILE_EMAIL = 'rotarysupportcenter@rotary.org',
  MY_ROTARY_SIGN_IN_OR_REGISTRATION_EMAIL = 'data@rotary.org',
  PROJECTS_EMAIL = 'rotarysupportcenter@rotary.org',
  ROTARY_CLUB_GOALS_EMAIL = 'rotarysupportcenter@rotary.org',
  ROTARY_GLOBAL_REWARDS_EMAIL = 'rotarysupportcenter@rotary.org',
  UPDATE_CLUB_OR_DISTRICT_INFORMATION_EMAIL = 'data@rotary.org',
  YOUTH_PROGRAMS_OR_SERVICE_EMAIL = 'rotarysupportcenter@rotary.org',
  OTHER_EMAIL = 'rotarysupportcenter@rotary.org',
}

export enum TESTING_SUBJECT_RECIPIENTS {
  BRAND_CENTER_EMAIL = 'rimailtest+Brand-Center@gmail.com',
  CONTRIBUTIONS_AND_RECOGNITION_EMAIL = 'rimailtest+Contributions-And-Recognition@gmail.com',
  CONVENTION_OR_INTERNATIONAL_ASSEMBLY_EMAIL = 'rimailtest+Convention-Or-International-Assembly@gmail.com',
  DISCUSSION_GROUPS_EMAIL = 'rimailtest+Discussion-Groups@gmail.com',
  MEMBERSHIP_EMAIL = 'rimailtest+Membership@gmail.com',
  MY_ACCOUNT_OR_PROFILE_EMAIL = 'rimailtest+My-Account-Or-Profile@gmail.com',
  MY_ROTARY_SIGN_IN_OR_REGISTRATION_EMAIL = 'rimailtest+My-Rotary-SignIn-Or-Registration@gmail.com',
  PROJECTS_EMAIL = 'rimailtest+Projects@gmail.com',
  ROTARY_CLUB_GOALS_EMAIL = 'rimailtest+Rotary-Club-Goals@gmail.com',
  ROTARY_GLOBAL_REWARDS_EMAIL = 'rimailtest+Rotary-Global-Rewards@gmail.com',
  UPDATE_CLUB_OR_DISTRICT_INFORMATION_EMAIL = 'rimailtest+Update-Club-Or-District-Information@gmail.com',
  YOUTH_PROGRAMS_OR_SERVICE_EMAIL = 'rimailtest+Youth-Programs-Or-Service@gmail.com',
  OTHER_EMAIL = 'rimailtest+Other@gmail.com',
}

export type Subject = {
  name: string;
};

export interface ContactUsFormType {
  email: string;
  firstName: string;
  lastName: string;
  primaryPhone: {
    number: string;
    country: string;
    countryId: string;
    extension: string;
  };
  country: string;
  district: string;
  club: string;
  subject: string;
  comment: string;
}

export type SubjectCategoryRecipientMapping = {
  [key: string]: string;
};
