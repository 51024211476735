import React from 'react';

import { useFormikContext } from 'formik';

import Select, { SelectProps } from '../../Formik/Select';

import { Subject, SUBJECT_VALUES } from '@domain/contact';

const generateOptions = (subjects: Subject[]) =>
  subjects.map(s => ({ label: s.name, value: s.name }));

type Props = Omit<SelectProps, 'options'>;

const ContactUsFormSubjectSelect: React.FC<Props> = props => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (value: string) => {
    const { name } = props;
    setFieldValue(name, value);
  };

  return (
    <Select
      onChange={handleChange}
      options={generateOptions(SUBJECT_VALUES)}
      {...props}
    />
  );
};

export default ContactUsFormSubjectSelect;
