import { Profession } from '@typings/graphql';
import { FetchProfessionalExperienceQuery } from '@typings/operations';
import { FormProfessionItem } from '../types';

type SelectInput = FetchProfessionalExperienceQuery['professionalExperience'];

// Filter out unwanted object properties, like __typename.
export const selectProfessionValues = (
  profession: FormProfessionItem | Profession | undefined
): Profession | null => {
  if (profession === undefined) {
    return null;
  }
  const { position, occupation, employer, id } = profession;
  return {
    position,
    occupation,
    employer,
    id,
  };
};

export const mapProfessionalExperienceDataToFormValues = (
  input: SelectInput
) => {
  let professionalExperiences: FormProfessionItem[] = [];
  if (input?.primaryProfession) {
    professionalExperiences = [
      { ...selectProfessionValues(input.primaryProfession), isPrimary: true },
    ];
  }
  if (input?.otherProfessions && input.otherProfessions.length > 0) {
    professionalExperiences = [
      ...professionalExperiences,
      ...input.otherProfessions.map(a => ({
        ...selectProfessionValues(a),
        isPrimary: false,
      })),
    ];
  }
  const sharingPermissionsExtended = input.sharingPermissionsExtended
    ? {
        profession: { id: input.sharingPermissionsExtended.profession.id },
      }
    : null;

  return { professionalExperiences, sharingPermissionsExtended };
};
