import { uniqBy } from 'lodash/fp';

import { getDistrictsList } from '@utils/districts';
import { getUserClubs } from '@utils/getUserClubs';

import { ClubAffiliation, DistrictType } from '@typings/graphql';

export const getUserCurrentDistrict = (clubAffiliations: ClubAffiliation[]) => {
  const userClubsForDistricts = getUserClubs(clubAffiliations, true);
  const districtsInfo: DistrictType[] = getDistrictsList(userClubsForDistricts);
  const districtTypes = uniqBy('id', [...districtsInfo]);

  return districtTypes.map(district => district.riDistrictId).toString();
};
