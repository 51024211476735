import React from 'react';

import Divider from '@components/Divider';
import Link from '@components/Link';

import {
  ROTARY_CLUB_AND_DISTRICT_SUPPORT_REPRESENTATIVES_URL,
  ROTARY_FINANCIAL_REPRESENTATIVES_URL,
  ROTARY_GREAT_BRETAIN_AND_IRELAND_URL,
  ROTARY_INTERNATIONAL_HEADQUARTERS_URL,
  ROTARY_INTERNATIONAL_OFFICES_URL,
} from '@constants/index';

import { useTranslation } from '@external/react-i18next';

export const ContactInformation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t('contact-information.heading.main', 'World headquarters')}</h3>

      {t(
        'contact-information.address',
        '<p>+1 866-976-8279 (toll free)<br />+1 847-866-3000<br />Rotary International<br />One Rotary Center<br />1560 Sherman Ave.<br />Evanston, IL 60201-3698, USA</p>'
      )}

      <h5 className="mt-6">
        {t('contact-information.heading.visit-us', 'Visit us')}
      </h5>
      <p className="tablet:max-w-sm tablet:pr-4">
        {t(
          'contact-information.content.visit-message',
          "Schedule your meeting at Rotary's world headquarters or take a guided tour of our offices, including the reproduction of Room 711, birthplace of Rotary."
        )}
      </p>
      <Link to={ROTARY_INTERNATIONAL_HEADQUARTERS_URL}>
        {t('contact-information.link.plan-visit', 'Plan your visit')}
      </Link>
      <Divider />

      <h3>
        {t('contact-information.heading.local-support', 'Need local support?')}
      </h3>
      <p>
        <Link to={ROTARY_INTERNATIONAL_OFFICES_URL}>
          {t(
            'contact-information.link.international-offices',
            'International offices'
          )}
        </Link>
      </p>
      <p>
        <Link to={ROTARY_GREAT_BRETAIN_AND_IRELAND_URL}>
          {t(
            'contact-information.link.great-bretain-and-ireland',
            'Rotary International in Great Britain and Ireland'
          )}
        </Link>
      </p>
      <p>
        <Link to={ROTARY_CLUB_AND_DISTRICT_SUPPORT_REPRESENTATIVES_URL}>
          {t(
            'contact-information.link.club-and-district-representatives',
            'Club and District Support representatives'
          )}
        </Link>
      </p>
      <p>
        <Link to={ROTARY_FINANCIAL_REPRESENTATIVES_URL}>
          {t(
            'contact-information.link.financial-representatives',
            'Financial representatives'
          )}
        </Link>
      </p>
      <Divider />
    </div>
  );
};

export default ContactInformation;
