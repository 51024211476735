import { isNotNullish } from '@typings/util';
import { EditProfessionsFormValues } from '../types';
import { selectProfessionValues } from './mapProfessionalExperienceDataToFormValues';

// Filter out unwanted object properties, like __typename.
export const mapFormValuesToUpdateProfessionalExperienceInput = (
  values: EditProfessionsFormValues
) => {
  return {
    primaryProfession: selectProfessionValues(
      values.professionalExperiences.find(profession => profession.isPrimary)
    ),
    otherProfessions: values.professionalExperiences
      .filter(experience => !experience.isPrimary)
      .map(selectProfessionValues)
      .filter(isNotNullish),
    sharingPermissionsExtended: values.sharingPermissionsExtended
      ? { profession: values.sharingPermissionsExtended.profession.id }
      : null,
  };
};
