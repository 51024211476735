import React from 'react';

import { useFormikContext } from 'formik';

import Select, { SelectProps } from '@components/Formik/Select';
import Loading from '@components/Loading';

import { useErrorHandling } from '@use-cases/notifications';

import { useDISCountries } from '@repositories/disCountry';

import { getSortedCountries } from '@utils/getSortedCountries';

import { Country } from '@typings/graphql';

const generateOptions = (countries: Country[]) =>
  countries.map(c => ({ label: c.name, value: c.name }));

type Props = Omit<SelectProps, 'options'>;

const ContactUsFormCountrySelect: React.FC<Props> = props => {
  const { data, error } = useDISCountries();

  useErrorHandling(error?.message, !!error, 'fetch-countries.error');

  const { setFieldValue } = useFormikContext();

  const handleChange = (value: string) => {
    const { name } = props;
    setFieldValue(name, value);
  };

  if (!data) {
    return <Loading inline small />;
  }

  return (
    <Select
      onChange={handleChange}
      searchable
      options={generateOptions(getSortedCountries(data.countries))}
      {...props}
    />
  );
};

export default ContactUsFormCountrySelect;
