import {
  FieldType,
  Program,
  ProgramDefinition,
  ProgramField,
} from '@typings/graphql';
import { selectFieldsFromProgramDefinition } from '@use-cases/profile/helpers';
import { FormValues } from '../types';

export const mapProgramDataToFormValues = (
  programDefinition: ProgramDefinition,
  individualProgram: Program
): FormValues => {
  // If field does not exist on individualProgram set a default value
  const getInitialValue = (fieldType: FieldType, field: ProgramField) => {
    if (fieldType === FieldType.Date) {
      if (!field) {
        return new Date();
      }

      return new Date(field.value);
    } else {
      if (!field) {
        return '';
      }

      return field.value;
    }
  };

  return selectFieldsFromProgramDefinition(programDefinition).reduce(
    (initialValues, { fieldId, type }) => {
      return {
        ...initialValues,
        [fieldId]: getInitialValue(
          type,
          individualProgram.fields.filter(f => f.fieldId === fieldId)[0]
        ),
      };
    },
    {}
  );
};
