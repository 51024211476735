/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { AddressInput, EmailInput } from '@typings/graphql';
import {
  IndividualPhoneInput,
  SharingPermissionsInput,
  UpdateContactInformationMutationVariables,
} from '@typings/operations';

import {
  ContactInformationFormValues,
  FormAddressItem,
  FormEmailItem,
  FormPhoneItem,
} from '../types';

export default ({
  emails,
  addresses,
  phones,
  sharingPermissionsExtended,
}: ContactInformationFormValues): Omit<
  UpdateContactInformationMutationVariables,
  'id'
> => {
  const selectEmailValues = ({ address, type }: FormEmailItem): EmailInput => ({
    address,
    type,
  });

  const selectAddressValues = ({
    type,
    country,
    countryId,
    city,
    postalCode,
    lineOne,
    lineTwo,
    lineThree,
    state,
    stateId,
    internationalProvince,
  }: FormAddressItem): AddressInput => {
    const address: AddressInput = {
      type,
      country,
      countryId,
      city: city!,
      postalCode: postalCode!,
      lineOne: lineOne!,
      lineTwo,
      lineThree,
      state,
    };

    if (stateId) {
      address.stateId = stateId;
    } else {
      address.internationalProvince = internationalProvince;
    }
    return address;
  };

  const selectPhoneValues = ({
    countryId,
    extension,
    number,
    type,
  }: FormPhoneItem): IndividualPhoneInput => ({
    countryId,
    extension,
    number,
    type: type!,
  });

  const sharingPermissionsInput: SharingPermissionsInput | null = sharingPermissionsExtended
    ? {
        email: sharingPermissionsExtended.email.id,
        phone: sharingPermissionsExtended.phone.id,
        address: sharingPermissionsExtended.address.id,
      }
    : null;

  return {
    primaryEmail: emails
      .filter(email => email.isPrimary)
      .map(selectEmailValues)[0],
    otherEmails: emails
      .filter(email => !email.isPrimary)
      .map(selectEmailValues),
    primaryAddress: addresses
      .filter(address => address.isPrimary)
      .map(selectAddressValues)[0],
    otherAddresses: addresses
      .filter(address => !address.isPrimary)
      .map(selectAddressValues),
    primaryPhone: phones
      .filter(phone => phone.isPrimary)
      .map(selectPhoneValues)[0],
    otherPhones: phones
      .filter(phone => !phone.isPrimary)
      .map(selectPhoneValues),
    sharingPermissionsExtended: sharingPermissionsInput,
  };
};
