import {
  ContactUsFormType,
  Subject,
  SUBJECT_CATEGORIES,
  SubjectCategoryRecipientMapping,
  TESTING_SUBJECT_RECIPIENTS,
} from './types';

export const SUBJECT_VALUES: Subject[] = [
  {
    name: SUBJECT_CATEGORIES.BRAND_CENTER,
  },
  {
    name: SUBJECT_CATEGORIES.CONTRIBUTIONS_AND_RECOGNITION,
  },
  {
    name: SUBJECT_CATEGORIES.CONVENTION_OR_INTERNATIONAL_ASSEMBLY,
  },
  {
    name: SUBJECT_CATEGORIES.DISCUSSION_GROUPS,
  },
  { name: SUBJECT_CATEGORIES.MEMBERSHIP },
  {
    name: SUBJECT_CATEGORIES.MY_ACCOUNT_OR_PROFILE,
  },
  {
    name: SUBJECT_CATEGORIES.MY_ROTARY_SIGN_IN_OR_REGISTRATION,
  },
  { name: SUBJECT_CATEGORIES.PROJECTS },
  {
    name: SUBJECT_CATEGORIES.ROTARY_CLUB_GOALS,
  },
  {
    name: SUBJECT_CATEGORIES.ROTARY_GLOBAL_REWARDS,
  },
  {
    name: SUBJECT_CATEGORIES.UPDATE_CLUB_OR_DISTRICT_INFORMATION,
  },
  {
    name: SUBJECT_CATEGORIES.YOUTH_PROGRAMS_OR_SERVICE,
  },
  { name: SUBJECT_CATEGORIES.OTHER },
];

export const SUBJECT_CATEGORY_RECIPIENT_MAPPING: SubjectCategoryRecipientMapping = {
  [SUBJECT_CATEGORIES.BRAND_CENTER]:
    TESTING_SUBJECT_RECIPIENTS.BRAND_CENTER_EMAIL,
  [SUBJECT_CATEGORIES.CONTRIBUTIONS_AND_RECOGNITION]:
    TESTING_SUBJECT_RECIPIENTS.CONTRIBUTIONS_AND_RECOGNITION_EMAIL,
  [SUBJECT_CATEGORIES.CONVENTION_OR_INTERNATIONAL_ASSEMBLY]:
    TESTING_SUBJECT_RECIPIENTS.CONVENTION_OR_INTERNATIONAL_ASSEMBLY_EMAIL,
  [SUBJECT_CATEGORIES.DISCUSSION_GROUPS]:
    TESTING_SUBJECT_RECIPIENTS.DISCUSSION_GROUPS_EMAIL,
  [SUBJECT_CATEGORIES.MEMBERSHIP]: TESTING_SUBJECT_RECIPIENTS.MEMBERSHIP_EMAIL,
  [SUBJECT_CATEGORIES.MY_ACCOUNT_OR_PROFILE]:
    TESTING_SUBJECT_RECIPIENTS.MY_ACCOUNT_OR_PROFILE_EMAIL,
  [SUBJECT_CATEGORIES.MY_ROTARY_SIGN_IN_OR_REGISTRATION]:
    TESTING_SUBJECT_RECIPIENTS.MY_ROTARY_SIGN_IN_OR_REGISTRATION_EMAIL,
  [SUBJECT_CATEGORIES.PROJECTS]: TESTING_SUBJECT_RECIPIENTS.PROJECTS_EMAIL,
  [SUBJECT_CATEGORIES.ROTARY_CLUB_GOALS]:
    TESTING_SUBJECT_RECIPIENTS.ROTARY_CLUB_GOALS_EMAIL,
  [SUBJECT_CATEGORIES.ROTARY_GLOBAL_REWARDS]:
    TESTING_SUBJECT_RECIPIENTS.ROTARY_GLOBAL_REWARDS_EMAIL,
  [SUBJECT_CATEGORIES.UPDATE_CLUB_OR_DISTRICT_INFORMATION]:
    TESTING_SUBJECT_RECIPIENTS.UPDATE_CLUB_OR_DISTRICT_INFORMATION_EMAIL,
  [SUBJECT_CATEGORIES.YOUTH_PROGRAMS_OR_SERVICE]:
    TESTING_SUBJECT_RECIPIENTS.YOUTH_PROGRAMS_OR_SERVICE_EMAIL,
  [SUBJECT_CATEGORIES.OTHER]: TESTING_SUBJECT_RECIPIENTS.OTHER_EMAIL,
};

export const CONTACT_US_FORM_INITIAL_VALUES: ContactUsFormType = {
  email: '',
  firstName: '',
  lastName: '',
  primaryPhone: {
    number: '',
    country: '',
    countryId: '',
    extension: '',
  },
  country: '',
  district: '',
  club: '',
  subject: '',
  comment: '',
};
