import { gql, useMutation } from '@apollo/client';

import {
  ContactUsMutation,
  ContactUsMutationVariables,
} from '@typings/operations';

export const mutation = gql`
  mutation contactUs(
    $contactForm: ContactFormInput!
    $email: String!
    $supportCenterEmail: String!
    $langcode: String!
    $recaptchaToken: String
  ) {
    contactUs(
      contactForm: $contactForm
      email: $email
      supportCenterEmail: $supportCenterEmail
      langcode: $langcode
      recaptchaToken: $recaptchaToken
    )
  }
`;

export const useContactUsMutation = () =>
  useMutation<ContactUsMutation, ContactUsMutationVariables>(mutation);
