import { getUserClubs } from '@utils/getUserClubs';

import { ClubAffiliation } from '@typings/graphql';

export const getUserCurrentClubs = (clubAffiliations: ClubAffiliation[]) => {
  const userClubs = getUserClubs(clubAffiliations) || [];
  return userClubs
    .map(
      club => `${club.clubName} (${club.clubType.replace(/club/i, ' Club')})`
    )
    .toString();
};
