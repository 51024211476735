// Libs
import moment from 'moment';

// Types
import {
  ProgramDefinition,
  FieldType,
  ProgramFieldInput,
} from '@typings/graphql';
import { FormValues } from '@domain/profile';
import { getFieldDefinitionById } from '@use-cases/profile/helpers';

export const mapFormValuesToUpdateProgramInput = (
  programDefinition: ProgramDefinition,
  values: FormValues
): ProgramFieldInput[] => {
  return Object.keys(values).map(key => {
    const isDate =
      getFieldDefinitionById(programDefinition, key).type === FieldType.Date;
    return {
      fieldId: key,
      value: isDate ? moment(values[key]).format('YYYY-MM-DD') : values[key],
    };
  });
};
