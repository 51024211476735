import { ContactInformationFormValues } from '@domain/profile';

import { FetchContactInformationQuery } from '@typings/operations';

type SelectInput = FetchContactInformationQuery['contactInformation'];

export default (input: SelectInput): ContactInformationFormValues => {
  let emails: ContactInformationFormValues['emails'] = [];
  let addresses: ContactInformationFormValues['addresses'] = [];
  let phones: ContactInformationFormValues['phones'] = [];

  if (input?.primaryEmail) {
    emails = [{ ...input.primaryEmail, isPrimary: true }];
  }

  if (input?.otherEmails?.length) {
    emails = [
      ...emails,
      ...input.otherEmails.map(a => ({ ...a, isPrimary: false })),
    ];
  }

  if (input?.primaryAddress) {
    addresses = [
      { ...input.primaryAddress, isPrimary: true, hasStates: false },
    ];
  }

  if (input?.otherAddresses?.length) {
    addresses = [
      ...addresses,
      ...input.otherAddresses.map(a => ({
        ...a,
        isPrimary: false,
        hasStates: false,
      })),
    ];
  }

  if (input?.primaryPhone) {
    phones = [{ ...input.primaryPhone, isPrimary: true }];
  }

  if (input?.otherPhones?.length) {
    phones = [
      ...phones,
      ...input.otherPhones.map(a => ({ ...a, isPrimary: false })),
    ];
  }

  return {
    addresses,
    phones,
    emails,
    sharingPermissionsExtended: input.sharingPermissionsExtended || null,
  };
};
