import { FetchAreasOfExpertiseQuery } from '@typings/operations';
import { FormExpertiseItem } from '../types';

type SelectInput = FetchAreasOfExpertiseQuery['areasOfExpertise'];

export const mapAreasOfExpertiseDataToFormValues = (input: SelectInput) => {
  let expertises: FormExpertiseItem[] = [];

  if (input?.primaryExpertise) {
    expertises = [{ ...input.primaryExpertise, isPrimary: true }];
  }

  if (input?.otherExpertise?.length) {
    expertises = [
      ...expertises,
      ...input.otherExpertise.map(a => ({
        ...a,
        isPrimary: false,
      })),
    ];
  }
  const sharingPermissionsExtended = {
    expertise: { id: input.sharingPermissionsExtended?.expertise.id || '' },
  };

  return { expertises, sharingPermissionsExtended };
};
