import { TFunction } from 'i18next';
import { flatten } from 'lodash';
import * as yup from 'yup';

import {
  addressLineValidation,
  cityValidation,
  postalCodeValidation,
  stateIdValidation,
  stateValidation,
} from '@components/Formik/Address/validation';
import dateOfBirthSchema from '@components/Formik/DateOfBirth/validation';
import genderSchema from '@components/Formik/GenderInputs/validation';
import personalNameSchema from '@components/Formik/PersonalNameForm/validation';
import {
  emailSchema,
  latinOnlyEmailSchema,
  numberRequiredSchema,
} from '@components/Formik/validation/fieldDefinitions';
import {
  hasMaxLength,
  isRequired as isRequiredCallback,
  matchLatinChars,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';
import firstLastNameSchema from '@presenters/web/components/Leads/Forms/FirstAndLastNameForm/validation';

import { MAX_BACKGROUND_CHAR, MAX_PROF_EXPERIENCE_CHAR } from '@domain/profile';

import { FieldCharSet, FieldType, ProgramDefinition } from '@typings/graphql';

export const addressValidationSchemaForProfile = (t: TFunction) =>
  yup.object().shape({
    lineOne: addressLineValidation(t)
      .required(
        t('form.address.error_line1_required', 'Address line 1 cannot be empty')
      )
      .when(['lineTwo', 'lineThree'], {
        is: (lineTwo, lineThree) => {
          return (
            (lineTwo && lineTwo.length > 0) ||
            (lineThree && lineThree.length > 0)
          );
        },
        then: yup.string().required(
          t(
            'form.address.error_line1_required',

            'Address line 1 cannot be empty'
          )
        ),
      })
      .nullable(),
    lineTwo: addressLineValidation(t)
      .when('lineThree', {
        is: lineThree => {
          return lineThree && lineThree.length > 0;
        },
        then: yup
          .string()
          .required(
            t(
              'form.address.error_line2_required',
              'Address line 2 cannot be empty'
            )
          ),
      })
      .nullable(),
    lineThree: addressLineValidation(t).nullable(),
    city: cityValidation(t),
    postalCode: postalCodeValidation(t),
    state: stateValidation(t),
    stateId: stateIdValidation(t),
    countryId: yup
      .string()
      .required(t('form.field.error_required', 'Required')),
    internationalProvince: yup
      .string()
      .nullable()
      .when('hasStates', {
        is: hasStates => !hasStates,
        then: schemaField(
          t,
          yup.string(),
          hasMaxLength(40),
          matchLatinChars
        ).nullable(),
      }),
  });

export const backgroundValidationSchema = (t: TFunction) =>
  yup.object().shape({
    aboutMe: schemaField(
      t,
      yup.string(),
      hasMaxLength(MAX_BACKGROUND_CHAR)
    ).nullable(),
  });

export const areasOfExpertiseValidationSchema = (t: TFunction) =>
  yup.object().shape({
    expertises: yup.array().of(
      yup.object().shape({
        areaId: schemaField(t, yup.string(), isRequiredCallback),
        levelId: schemaField(t, yup.string(), isRequiredCallback),
      })
    ),
  });

export const countryIdValidation = (t: TFunction) =>
  schemaField(t, yup.string(), isRequiredCallback);

export const extensionValidation = (t: TFunction) =>
  schemaField(t, yup.string(), hasMaxLength(10))
    .matches(
      /^[0-9,().\-/ ]+$/,
      t(
        'edit-profile.phone.form.number.error_can_only_contain',
        'Field can only contain numbers, space " ", dash "-", slash "/", comma ",", parentheses "()" or dot "."'
      )
    )
    .matches(
      /^[(0-9]/,
      t(
        'edit-profile.phone.form.number.error_can_only_contain',
        'Field must start with either a number or open parentheses "("'
      )
    )
    .nullable();

const typeValidation = (t: TFunction) =>
  yup.string().required(t('form.field.error_required', 'Required'));

const editRejoinProfileEmailValidation = (t: TFunction) =>
  yup.array().of(
    yup.object().shape({
      address: latinOnlyEmailSchema(t).required(
        t('form.field.error_required', 'Required')
      ),
    })
  );

const emailValidationForEditprofile = (t: TFunction) =>
  yup.array().of(
    yup.object().shape({
      address: emailSchema(t).required(
        t('form.field.error_required', 'Required')
      ),
    })
  );

const phoneInfoValidationSchema = (t: TFunction) =>
  yup.array().of(
    yup.object().shape({
      countryId: countryIdValidation(t),
      extension: extensionValidation(t),
      number: numberRequiredSchema(t),
      type: typeValidation(t),
    })
  );

export const contactInformationValidationSchema = (t: TFunction) => {
  const schema = yup.object().shape({
    emails: emailValidationForEditprofile(t),
    addresses: yup.array().of(addressValidationSchemaForProfile(t)),
    phones: phoneInfoValidationSchema(t),
  });
  return schema;
};

export const profileDetailsForRejoinValidationSchema = (t: TFunction) => {
  const schema = yup.object().shape({
    emails: editRejoinProfileEmailValidation(t),
    addresses: yup.array().of(addressValidationSchemaForProfile(t)),
    phones: phoneInfoValidationSchema(t),
  });
  return firstLastNameSchema(t).concat(schema);
};

export const personalDetailsValidationSchema = (t: TFunction) =>
  personalNameSchema(t)
    .concat(dateOfBirthSchema(t))
    .concat(genderSchema);

export const professionalExperienceValidationSchema = (t: TFunction) =>
  yup.object().shape({
    professionalExperiences: yup.array().of(
      yup.object().shape({
        employer: schemaField(
          t,
          yup.string(),
          hasMaxLength(MAX_PROF_EXPERIENCE_CHAR)
        ),
        position: schemaField(
          t,
          yup.string(),
          hasMaxLength(MAX_PROF_EXPERIENCE_CHAR)
        ),
        occupation: schemaField(
          t,
          yup.string(),
          hasMaxLength(MAX_PROF_EXPERIENCE_CHAR)
        ).when(['position', 'employer'], {
          is: (position, employer) =>
            position === undefined && employer === undefined,
          then: yup
            .string()
            .required(
              t(
                'form.field.at-least-one',
                'Please fill in at least one input field.'
              )
            ),
        }),
      })
    ),
  });

export const buildProgramValidationSchema = (
  t: TFunction,
  programDefinition: ProgramDefinition
) =>
  yup.object().shape(
    flatten(programDefinition.fieldGroups.map(fg => fg.fields)).reduce(
      (shape, { fieldId, type, isRequired, constraints }) => {
        let fieldSchema: yup.StringSchema | yup.DateSchema =
          type === FieldType.Date ? yup.date() : yup.string();

        if (isRequired) {
          fieldSchema = fieldSchema.required(
            t('form.field.error_required', 'Required')
          );
        }

        if (type === FieldType.String && constraints) {
          fieldSchema = (fieldSchema as yup.StringSchema).max(
            constraints.maxLength,
            t(
              'form.field.error_too_long',
              'Field cannot exceed {{number}} characters',
              { number: constraints.maxLength }
            )
          );

          if (
            constraints.charSet &&
            constraints.charSet === FieldCharSet.OnlyDigits
          ) {
            fieldSchema = fieldSchema.matches(
              /^[\d]*$/,
              t('form.field.error_only_digits', 'Field can only contain digits')
            );
          }

          if (
            constraints.charSet &&
            constraints.charSet === FieldCharSet.OnlyLatin
          ) {
            fieldSchema = fieldSchema.matches(
              /\w+/,
              t(
                'form.field.error_non_lating',
                'Field cannot contain non-latin characters'
              )
            );
          }
        }

        return { ...shape, [fieldId]: fieldSchema };
      },
      {}
    )
  );
