// Libs
import moment from 'moment';

// Types
import {
  FieldType,
  ProgramDefinition,
  ProgramFieldInput,
} from '@typings/graphql';
import { FormValues } from '../types';

// Utils
import { getFieldDefinitionById } from '@use-cases/profile';

export const mapFormValuesToCreateProgramInput = (
  programDefinition: ProgramDefinition,
  values: FormValues
): ProgramFieldInput[] => {
  return Object.keys(values).map(key => {
    const isDate =
      getFieldDefinitionById(programDefinition, key).type === FieldType.Date;
    return {
      fieldId: key,
      value: isDate ? moment(values[key]).format('YYYY-MM-DD') : values[key],
    };
  });
};
